import React, { useState, useEffect } from 'react'
import './PopupWindowBuildingDetail.css'
import httpClient from './helpers/httpClient'
import CloseIcon from '@mui/icons-material/Close'
import { toast } from "react-toastify"
import Slider from './Slider'
import { PopupWindow } from './PopupWindow'
import { DateField } from '@mui/x-date-pickers'
import Dropdown from '../components/Dropdown'
import { noteTypes } from '../assets/data/data'
import { formatDate } from '../components/helpers/formatDate'
import Note from '../components/Note'
import { isValidDateDayJS } from './helpers/globalFns'

function PopupWindowBuildingDetail({
	isActive, setIsActive, onClose, actionType, selectedItem, setSelectedItem, projectID, selectedItemID, fetchProject, contactsList
}) {
	const [selectedNote, setSelectedNote] = useState()
	const [notes, setNotes] = useState([])
	const [notePopupIsActive, setNotePopupIsActive] = useState(false)
	const [noteDetailPopupIsActive, setNoteDetailPopupIsActive] = useState(false)
	const [newNoteObj, setNewNoteObj] = useState({})

	const fetchNotes = async () => {
		if(!selectedItem.id) {
			return
		}

		const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/notes/get/${selectedItem.id}?type=building`)
		if (response.status == 200) {
			setNotes(response.data)
		}
	}

	useEffect(() => {
		const popup = document.getElementById('popup-project-detail')
		window.addEventListener('keydown', e => {
			if (e.key === 'Escape') {
				if (popup.classList.contains("active")) {
					closePopupWindow()
				}
			}
		})

	}, [])

	useEffect(() => {
		if (isActive) {
			fetchNotes()
		}
	}, [isActive, selectedItem])

	const closePopupWindow = () => {
		setIsActive(false)
	}

	const handleSetCoOwned = (idNr, state) => {
		setSelectedItem(prevState => ({ ...prevState, "co_ownership": state }))
	}

	const handleSaveNewObject = async () => {

		if (notePopupIsActive) {
			if (!isValidDateDayJS(newNoteObj?.noteDate)) {
				toast.error("Date not valid or is empty")
				return
			}

			if (!newNoteObj.noteType || !newNoteObj.contact || !newNoteObj.subject) {
				toast.error("Type, contact and subject are required")
				return
			}

			const data = { ...newNoteObj }
			data["buildingID"] = selectedItem.id

			const responseProjectNote = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/notes/add`, data),
				{
					success: `Note has been created!`,
					error: "Something went wrong",
					pending: `Creating note...`
				})

			// cleanup state
			const prevStateNewNote = { ...newNoteObj }
			Object.keys(prevStateNewNote).forEach(key => {
				prevStateNewNote[key] = ''
			})
			setNewNoteObj(prevStateNewNote)

			fetchNotes()
		} else {
			if (!selectedItem.building_name) {
				toast.error("Name is obligatory")
				return
			}

			const responseBuilding = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/buildings/create?projectID=${projectID}&itemID=${selectedItemID}&actionType=${actionType}`, selectedItem),
				{
					success: `Building has been ${actionType == "add" ? "created" : "updated"}!`,
					error: "Something went wrong",
					pending: `${actionType == "add" ? "creating" : "updating"} building...`
				})

			// cleanup state
			const prevStateSelectedItem = { ...selectedItem }
			Object.keys(prevStateSelectedItem).forEach(key => {
				prevStateSelectedItem[key] = ''
			})
			setSelectedItem(prevStateSelectedItem)

			const prevStateNewNote = { ...newNoteObj }
			Object.keys(prevStateNewNote).forEach(key => {
				prevStateNewNote[key] = ''
			})
			setNewNoteObj(prevStateNewNote)

			setIsActive(false)
		}

		fetchProject()
		setNoteDetailPopupIsActive(false)
		setNotePopupIsActive(false)
	}

	const handleOnChangeInput = (e) => {
		const key = e.currentTarget.getAttribute("name")
		const value = e.currentTarget.value

		const prevState = { ...selectedItem }
		prevState[key] = value

		setSelectedItem(prevState)
	}

	const setStateNewNote = (key, value) => {
		const prevState = { ...newNoteObj }
		prevState[key] = value
		setNewNoteObj(prevState)
	}

	const handleCreateNote = () => {
		setNotePopupIsActive(true)
	}

	return (
		<div className={`popup__container building-detail overlay no-scrollbar ${isActive ? "active" : ""}`} id='popup-project-detail'>
			<div className='popup__box no-scrollbar'>
				<CloseIcon id="popup__close-icon" onClick={closePopupWindow} />

				<div className={`popout_body ${actionType == 'add' ? "small" : ""}`} id='popout-body-building'>

					<div className='popout_window__header'>
						<button className='primary-button-header' onClick={handleSaveNewObject}>SAVE</button>
						<h3>{actionType == "add" ? "add building" : "building"}</h3>
					</div>

					<div className={`details_container ${actionType == 'add' ? "small" : ""}`}>
						<div className='panel'>
							<div className='body__section'>
								<h2>Details</h2>
								<div className='form__section'>
									<div className='form__section--content'>
										<div className="input_field">
											<label className="input_field__label" htmlFor="building">Name</label>
											<input className="input_field__input_box--text" id="building_name" type="text" name="building_name" value={selectedItem.building_name} onChange={handleOnChangeInput} />
										</div>

										<div className="input_field--combo">
											<div className="input_field">
												<label className="input_field__label" htmlFor="GFA">GFA</label>
												<input className="input_field__input_box--text" id="building_GFA" type="number" name="building_GFA" value={selectedItem.GFA} onChange={handleOnChangeInput} />
											</div>
											<div className="input_field">
												<label className="input_field__label" htmlFor="GFAa_underground">GFA underground</label>
												<input className="input_field__input_box--text" id="building_GFAa_underground" type="number" name="building_GFAa_underground" value={selectedItem.GFAa_underground} onChange={handleOnChangeInput} />
											</div>
											<div className="input_field">
												<label className="input_field__label" htmlFor="GFAa">GFAa</label>
												<input className="input_field__input_box--text" id="building_GFAa" type="number" name="building_GFAa" value={selectedItem.GFAa} onChange={handleOnChangeInput} />
											</div>
										</div>

										<div className="input_field--combo">
											<div className="input_field">
												<label className="input_field__label" htmlFor="GFAb">GFAb</label>
												<input className="input_field__input_box--text" id="building_GFAb" type="number" name="building_GFAb" value={selectedItem.GFAb} onChange={handleOnChangeInput} />
											</div>
											<div className="input_field">
												<label className="input_field__label" htmlFor="GFAc">GFAc</label>
												<input className="input_field__input_box--text" id="building_GFAc" type="number" name="building_GFAc" value={selectedItem.GFAc} onChange={handleOnChangeInput} />
											</div>
											<div className="input_field">
												<label className="input_field__label" htmlFor="parkings">parkings</label>
												<input className="input_field__input_box--text" id="building_parkings" type="number" name="building_parkings" value={selectedItem.parkings} onChange={handleOnChangeInput} />
											</div>
										</div>

										<Slider
											idNr={"building_co-ownership"}
											text={"Co-ownership"}
											size={1.5}
											fontSize={"0.9rem"}
											margin="20px 0 7.5px 0"
											state={selectedItem.co_ownership}
											onClickFunction={handleSetCoOwned}
										/>
										{
											selectedItem?.co_ownership &&
											<div className="input_field">
												<label className="input_field__label" htmlFor="shares_co_ownership">Shares in co ownership</label>
												<input className="input_field__input_box--text" id="building_shares_co_ownership" type="number" name="shares_co_ownership" value={selectedItem.shares_co_ownership} onChange={handleOnChangeInput} />
											</div>
										}
									</div>
								</div>
							</div>
						</div>
						{
							actionType != 'add' &&
							<div className='panel'>
								<div className='body__section'>
									<h2>Notes</h2>
									<div className='form__section'>
										<div className='form__section--content' >

											<PopupWindow popupID={"popup-building-notes-create"} isActive={notePopupIsActive} setIsActive={setNotePopupIsActive}>
												<div className='note__container--popup'>

													<div className='form__section--content' >
														<div className="input_field">
															<label className="input_field__label" htmlFor="note_date">Note date</label>
															<DateField className="date-picker" format="DD-MM-YYYY" value={newNoteObj.noteDate} onChange={(value) => setStateNewNote("noteDate", value)} />
														</div>

														<div className="input_field">
															<label className="input_field__label" htmlFor="note_type">Note type</label>
															<Dropdown
																stateType={"object"}
																stateKey={"noteType"}
																index={"note-type"}
																listArray={Object.keys(noteTypes)}
																state={newNoteObj.noteType}
																setState={setStateNewNote}
																placeholder={"Select note type"}
																width={"50%"}
																height="40px"
															/>
														</div>

														<div className="input_field">
															<label className="input_field__label" htmlFor="note_type">Contact</label>
															<Dropdown
																stateType={"object"}
																stateKey={"contact"}
																index={"contact-note"}
																listArray={contactsList}
																setState={setStateNewNote}
																placeholder={"Select contact"}
																width={"50%"}
																height="40px"
																searchBar={true}
															/>
														</div>

														<div className="input_field">
															<label className="input_field__label" htmlFor="note_subject">Subject</label>
															<input className="input_field__input_box--text" id="note_subject" type="text" name="note_subject" value={newNoteObj.note_subject} onChange={(e) => setStateNewNote("subject", e.currentTarget.value)} />
														</div>

														<div className="input_field">
															<label className="input_field__label" htmlFor="note_content">Content</label>
															<textarea className="input_field__input_box--text-area" id="note_content" type="text" name="note_content" value={newNoteObj.note_content} onChange={(e) => setStateNewNote("content", e.currentTarget.value)} />
														</div>

													</div>
												</div>
											</PopupWindow>

											<PopupWindow popupID={"popup-building-notes-detail"} isActive={noteDetailPopupIsActive} setIsActive={setNoteDetailPopupIsActive}>
												{
													selectedNote != null &&
													<div className='note__container--popup'>
														<div className='date_note--popup'>
															<div className='popup_window__subtitle'>Date:</div>
															<p>{formatDate(selectedNote.date_note)}</p>
														</div>
														<div className='date_note--popup'>
															<div className='popup_window__subtitle'>Created by:</div>
															<p>{selectedNote.note_maker_user_id?.first_name}</p>
														</div>
														<div className='date_note--popup'>
															<div className='popup_window__subtitle'>Contact:</div>
															{
																selectedNote.contact_id ?
																	<p>{selectedNote.contact_id.first_name} {selectedNote.contact_id.last_name}</p> :
																	selectedNote.company_id ?
																		<p>{selectedNote.company_id.company_name} (company)</p> : null
															}
														</div>
														<div className='date_note--popup'>
															<div className='popup_window__subtitle'>Subject:</div>
															<p>{selectedNote.subject}</p>
														</div>
														<div className='note_text--popup'>
															<div className='popup_window__subtitle'>Note:</div>
															<p>{selectedNote.note}</p>
														</div>
													</div>
												}
											</PopupWindow>

											<button className='more--btn' onClick={handleCreateNote}>+</button>
											{
												notes.map((note, index) => {
													return (
														<Note
															popupWindowID="popup-contact-notes"
															key={index}
															noteObj={note}
															type={note.type}
															note={note.note}
															subject={note.subject}
															date={note.date_note}
															setSelectedNote={setSelectedNote}
															setPopupActive={setNoteDetailPopupIsActive}
														/>
													)
												})
											}

										</div>
									</div>
								</div>
							</div>
						}
					</div>

				</div>

			</div>
		</div>
	)
}

export default PopupWindowBuildingDetail