import React, { useState, useEffect } from "react"
import httpClient from "./helpers/httpClient"
import "./MenuBar.css"
import { useSelector, useDispatch } from "react-redux"
import { setStateMiniMenu } from "../store/miniMenu"
import { Link } from "react-router-dom"
import { resetStateCompaniesPage } from "../store/companiesPage"
import { resetStateContactsPage } from "../store/contactsPage"
import { resetStateProjectsPage } from "../store/projectsPage"

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined'
import DashboardIcon from '@mui/icons-material/Dashboard'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import BusinessIcon from '@mui/icons-material/Business'
import AllInboxIcon from '@mui/icons-material/AllInbox'
import EngineeringIcon from '@mui/icons-material/Engineering'
import ListIcon from '@mui/icons-material/List'
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'

function MenuBar() {
  const [user, setUser] = useState({ email: "", id: "" })
  const [error, setError] = useState(false)

  const dispatch = useDispatch()
  const vitruviLogoBlack = require(`../assets/logos/Logo-Vitruvi-transparant_Wit.png`)
  const userInfo = useSelector(state => state.user)
  const miniMenu = useSelector(state => state.miniMenu)

  useEffect(() => {
    const aside_menu = document.querySelector("#template__aside")
    miniMenu ? aside_menu.classList.add("mini") : aside_menu.classList.remove("mini")

  }, [])

  const handleClickMenuItem = (e) => {
    const menu = e.currentTarget.parentElement.nextSibling
    const asideMenu = document.querySelector("#template__aside")
    const allSubMenus = document.querySelectorAll("#sub-menu")

    if(!menu) return
    if(menu.id !== "sub-menu") return allSubMenus.forEach(menu => menu.classList.remove("active"))

    menu.classList.toggle("active")
    asideMenu.classList.remove("mini")
  }

  const handleMenuSlider = () => {
    const asideMenu = document.querySelector("#template__aside")
    const secondairyMenus = document.querySelectorAll(".sub-menu")

    dispatch(setStateMiniMenu(!miniMenu))
    asideMenu.classList.toggle("mini")
    secondairyMenus.forEach(item => {
      item.classList.remove("active")
    })
  }

  const logoutUser = async () => {

    await httpClient.post(process.env.REACT_APP_API_URL + "/api/logout")
    window.location.href = "/"
    dispatch(resetStateCompaniesPage())
    dispatch(resetStateContactsPage())
    dispatch(resetStateProjectsPage())
  }

  return (
    <div className="template__aside" id="template__aside">
      <button className="menu-Slider" onClick={handleMenuSlider}><KeyboardArrowLeftOutlinedIcon /></button>
      <img src={vitruviLogoBlack} alt="stoqup logo black" />
      <div className="menu">

        <div className="primary__menu">
          <Link to="/dashboard">
            <div className="menu__item" onClick={handleClickMenuItem}><DashboardIcon /><p className="menu__title">Dashboard</p></div>
          </Link>
          <Link to="/contacts">
            <div className="menu__item" onClick={handleClickMenuItem}><RecentActorsIcon /><p className="menu__title">Contacts</p></div>
          </Link>
          <Link to="/companies">
            <div className="menu__item" onClick={handleClickMenuItem}><BusinessIcon /><p className="menu__title">Companies</p></div>
          </Link>
          <Link to="/products">
            <div className="menu__item" onClick={handleClickMenuItem}><AllInboxIcon /><p className="menu__title">Products</p></div>
          </Link>
          <Link to="/projects">
            <div className="menu__item" onClick={handleClickMenuItem}><EngineeringIcon /><p className="menu__title">Projects</p></div>
          </Link>

          {/* TODO */}
          {/* <Link to="/choices">
            <div className="menu__item" onClick={handleClickMenuItem}><ListIcon /><p className="menu__title">Choices</p></div>
          </Link>
          <Link to="/contracts">
            <div className="menu__item" onClick={handleClickMenuItem}><TextSnippetIcon /><p className="menu__title">Contracts</p></div>
          </Link> */}

          {/* ANCHOR Submenu example */}
          {/* <div><div className="menu__item" onClick={handleClickMenuItem}><Groups2OutlinedIcon /><p className="menu__title">Salesforce</p></div></div>
          <ul className="sub-menu" id="sub-menu">
            <Link to="/brandowner/salesforce/myteam"><li>My Team</li></Link>
            <Link to="/brandowner/salesforce/reports"><li>Visits Report</li></Link>
            <Link to="/brandowner/salesforce/performance"><li>Performance</li></Link>
          </ul> */}
        </div>

        <div className="secondairy__menu">
          <hr />
          <Link to="/settings"><div className="menu__item"><SettingsOutlinedIcon /><p className="menu__title">Settings</p></div></Link>
          <div className="menu__item" onClick={logoutUser}><LogoutOutlinedIcon /><p className="menu__title">Logout</p></div>
        </div>
      </div>
    </div>
  )
}

export default MenuBar
