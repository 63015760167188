import React, { useState, useEffect } from 'react'
import './PopupWindowUnitDetail.css'
import httpClient from './helpers/httpClient'
import CloseIcon from '@mui/icons-material/Close'
import { toast } from "react-toastify"
import { PopupWindow } from './PopupWindow'
import { DateField } from '@mui/x-date-pickers'
import Dropdown from '../components/Dropdown'
import { noteTypes } from '../assets/data/data'
import { formatDate } from '../components/helpers/formatDate'
import Note from '../components/Note'
import { isValidDateDayJS } from './helpers/globalFns'
import MiniTable from './MiniTable'
import Sold from '../assets/pictos/svg/Sold'

function PopupWindowUnitDetail({ isActive, setIsActive, actionType, projectID, project, selectedItemID, fetchProject, contactsList }) {
  const [selectedNote, setSelectedNote] = useState()
  const [notes, setNotes] = useState([])
  const [notePopupIsActive, setNotePopupIsActive] = useState(false)
  const [noteDetailPopupIsActive, setNoteDetailPopupIsActive] = useState(false)
  const [newNoteObj, setNewNoteObj] = useState({})
  const [unit, setUnit] = useState(null)

  const fetchNotes = async () => {
    if (!unit) {
      return
    }

    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/notes/get/${unit.id}?type=unit`)
    if (response.status == 200) {
      setNotes(response.data)
    }
  }

  const getUnitById = (project, unitId) => {
    if (!project.buildings) {
      return null
    }

    for (const building of project.buildings) {
      const unit = building.units.find(unit => unit.id == unitId)
      if (unit) {
        return unit
      }
    }
    return null
  }

  useEffect(() => {
    const popup = document.getElementById('popup-unit-detail')
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })
  }, [])

  useEffect(() => {
    if (project) {
      setUnit(getUnitById(project, selectedItemID))
    }
  }, [project, isActive])

  useEffect(() => {
    if (isActive) {
      fetchNotes()
    }
  }, [isActive, unit])

  const closePopupWindow = () => {
    setIsActive(false)
  }

  const handleSaveNewObject = async () => {
    if (notePopupIsActive) {
      if (!isValidDateDayJS(newNoteObj?.noteDate)) {
        toast.error("Date not valid or is empty")
        return
      }

      if (!newNoteObj.noteType || !newNoteObj.contact || !newNoteObj.subject) {
        toast.error("Type, contact and subject are required")
        return
      }

      const data = { ...newNoteObj }
      data["unitID"] = unit.id

      const responseProjectNote = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/notes/add`, data),
        {
          success: `Note has been created!`,
          error: "Something went wrong",
          pending: `Creating note...`
        })

      // cleanup state
      const prevStateNewNote = { ...newNoteObj }
      Object.keys(prevStateNewNote).forEach(key => {
        prevStateNewNote[key] = ''
      })
      setNewNoteObj(prevStateNewNote)

      setNoteDetailPopupIsActive(false)
      setNotePopupIsActive(false)
      fetchNotes()
    } else {

      if (!unit.unit_name) {
        toast.error("Name is obligatory")
        return
      }
      const responseUnit = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/units/create?itemID=${selectedItemID}&actionType=${actionType}`, unit),
        {
          success: `Unit has been ${actionType == "add" ? "created" : "updated"}!`,
          error: "Something went wrong",
          pending: `${actionType == "add" ? "creating" : "updating"} unit...`
        })

      // cleanup state
      const prevStateSelectedItem = { ...unit }
      Object.keys(prevStateSelectedItem).forEach(key => {
        prevStateSelectedItem[key] = ''
      })

      fetchNotes()
      setIsActive(false)
      setUnit(prevStateSelectedItem)
    }

    fetchProject()
  }

  const handleOnChangeInput = (e) => {
    const key = e.currentTarget.getAttribute("name")
    const value = e.currentTarget.value

    const prevState = { ...unit }
    prevState[key] = value

    setUnit(prevState)
  }

  const setStateNewNote = (key, value) => {
    const prevState = { ...newNoteObj }
    prevState[key] = value
    setNewNoteObj(prevState)
  }

  const handleCreateNote = () => {
    setNotePopupIsActive(true)
  }

  const handleAddCadastralNumber = async (value) => {
    const data = {
      "cadastral_nr": value
    }
    const response = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/units/create?itemID=${unit.id}&actionType=ki`, data),
      {
        success: `Cadastral number has been added`,
        error: "Something went wrong",
        pending: `Adding cadastral number...`
      })

    fetchProject()
  }

  const handleDeleteCadastralNr = async (data) => {

    const response = await toast.promise(httpClient.delete(process.env.REACT_APP_API_URL + `/api/units/create?itemID=${unit.id}&actionType=ki`,
      {
        data: data,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ),
      {
        success: `Cadastral number has been deleted`,
        error: "Something went wrong",
        pending: `Deleting cadastral number...`
      })

    fetchProject()
  }

  return (
    <div className={`popup__container unit-detail overlay no-scrollbar ${isActive ? "active" : ""}`} id='popup-unit-detail'>
      <div className='popup__box no-scrollbar'>
        <CloseIcon id="popup__close-icon" onClick={closePopupWindow} />

        <div className='popout_body' id='popout-body-unit'>
          <div className='popout_window__header'>
            <button className='primary-button-header' onClick={handleSaveNewObject}>SAVE</button>
            <h3>{actionType == "add" ? "add unit" : "unit"}</h3>
          </div>

          <div className={`details_container ${actionType == 'add' ? "small" : ""}`}>
            <div className='panel'>
              <div className='body__section'>
                <h2>Details</h2>
                <div className='form__section no-border no-padding'>

                  <div className='form__section'>
                    <div className='form__section--content'>
                      <div className="input_field">
                        <label className="input_field__label" htmlFor="unit_name">Unit name</label>
                        <input className="input_field__input_box--text" id="unit_name" type="text" name="unit_name" value={unit?.unit_name} onChange={handleOnChangeInput} />
                      </div>

                      <div className="input_field">
                        <label className="input_field__label" htmlFor="unit_address">Address</label>
                        <input className="input_field__input_box--text" id="unit_address" type="text" name="address" value={unit?.address} onChange={handleOnChangeInput} />
                      </div>

                      <div className="input_field">
                        <label className="input_field__label" htmlFor="unit_gla">gla</label>
                        <input className="input_field__input_box--text" id="unit_gla" type="number" name="gla" value={unit?.gla} onChange={handleOnChangeInput} />
                      </div>
                      <div className="input_field">
                        <label className="input_field__label" htmlFor="ki_numbers">Cadastral numbers</label>
                        <MiniTable
                          data={unit?.KI_numbers}
                          columnKeyNames={["cadastral_nr"]}
                          newItemFunctions={{ "cadastral_nr": handleAddCadastralNumber }}
                          deleteRowFn={handleDeleteCadastralNr}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {
              actionType != 'add' &&
              <div className='panel'>
                <div className='body__section'>
                  <h2>Notes</h2>
                  <div className='form__section'>
                    <div className='form__section--content'>

                      <PopupWindow popupID={"popup-unit-notes-create"} isActive={notePopupIsActive} setIsActive={setNotePopupIsActive}>
                        <div className='note__container--popup'>

                          <div className='form__section no-border no-padding' >
                            <div className="input_field">
                              <label className="input_field__label" htmlFor="note_date">Note date</label>
                              <DateField className="date-picker" format="DD-MM-YYYY" value={newNoteObj.noteDate} onChange={(value) => setStateNewNote("noteDate", value)} />
                            </div>

                            <div className="input_field">
                              <label className="input_field__label" htmlFor="note_type">Note type</label>
                              <Dropdown
                                stateType={"object"}
                                stateKey={"noteType"}
                                index={"note-type"}
                                listArray={Object.keys(noteTypes)}
                                state={newNoteObj.noteType}
                                setState={setStateNewNote}
                                placeholder={"Select note type"}
                                width={"50%"}
                                height="40px"
                              />
                            </div>

                            <div className="input_field">
                              <label className="input_field__label" htmlFor="note_type">Contact</label>
                              <Dropdown
                                stateType={"object"}
                                stateKey={"contact"}
                                index={"contact-note"}
                                listArray={contactsList}
                                setState={setStateNewNote}
                                placeholder={"Select contact"}
                                width={"50%"}
                                height="40px"
                                searchBar={true}
                              />
                            </div>

                            <div className="input_field">
                              <label className="input_field__label" htmlFor="note_subject">Subject</label>
                              <input className="input_field__input_box--text" id="note_subject" type="text" name="note_subject" value={newNoteObj.note_subject} onChange={(e) => setStateNewNote("subject", e.currentTarget.value)} />
                            </div>

                            <div className="input_field">
                              <label className="input_field__label" htmlFor="note_content">Content</label>
                              <textarea className="input_field__input_box--text-area" id="note_content" type="text" name="note_content" value={newNoteObj.note_content} onChange={(e) => setStateNewNote("content", e.currentTarget.value)} />
                            </div>

                          </div>
                        </div>
                      </PopupWindow>

                      <PopupWindow popupID={"popup-unit-notes-detail"} isActive={noteDetailPopupIsActive} setIsActive={setNoteDetailPopupIsActive}>
                        {
                          selectedNote != null &&
                          <div className='note__container--popup'>
                            <div className='date_note--popup'>
                              <div className='popup_window__subtitle'>Date:</div>
                              <p>{formatDate(selectedNote.date_note)}</p>
                            </div>
                            <div className='date_note--popup'>
                              <div className='popup_window__subtitle'>Created by:</div>
                              <p>{selectedNote.note_maker_user_id?.first_name}</p>
                            </div>
                            <div className='date_note--popup'>
                              <div className='popup_window__subtitle'>Contact:</div>
                              {
                                selectedNote.contact_id ?
                                  <p>{selectedNote.contact_id.first_name} {selectedNote.contact_id.last_name}</p> :
                                  selectedNote.company_id ?
                                    <p>{selectedNote.company_id.company_name} (company)</p> : null
                              }
                            </div>
                            <div className='date_note--popup'>
                              <div className='popup_window__subtitle'>Subject:</div>
                              <p>{selectedNote.subject}</p>
                            </div>
                            <div className='note_text--popup'>
                              <div className='popup_window__subtitle'>Note:</div>
                              <p>{selectedNote.note}</p>
                            </div>
                          </div>
                        }
                      </PopupWindow>

                      <button className='more--btn' onClick={handleCreateNote}>+</button>
                      {
                        notes.map((note, index) => {
                          return (
                            <Note
                              popupWindowID="popup-contact-notes"
                              key={index}
                              noteObj={note}
                              type={note.type}
                              note={note.note}
                              subject={note.subject}
                              date={note.date_note}
                              setSelectedNote={setSelectedNote}
                              setPopupActive={setNoteDetailPopupIsActive}
                            />
                          )
                        })
                      }

                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

      </div>
    </div>
  )
}

export default PopupWindowUnitDetail