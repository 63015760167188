import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux"
import storage from 'redux-persist/lib/storage'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import userReducer from './user'
import miniMenuReducer from './miniMenu'
import contactPageReducer from './contactsPage'
import companiesPageReducer from './companiesPage'
import productsPageReducer from './productsPage'
import projectsPageReducer from './projectsPage'

const reducers = combineReducers({
  user: userReducer,
  miniMenu: miniMenuReducer,
  contactsPage: contactPageReducer,
  companiesPage: companiesPageReducer,
  productsPage: productsPageReducer,
  projectsPage: projectsPageReducer,
})

// Add a migration object
const migrations = {
  1: (state) => {
    return {
      ...state,
      companiesPage: {
        ...state.companiesPage,
        companyFunctions: [],
      },
    }
  },
}

const persistConfig = {
  key: 'vitruvi',
  version: 2, // Increment this when adding a new migration
  storage,
  migrate: (state, version) => {
    // Custom migration function
    let newState = { ...state }
    let currentVersion = version || 1

    while (currentVersion < persistConfig.version) {
      if (migrations[currentVersion]) {
        newState = migrations[currentVersion](newState)
      }
      currentVersion++
    }

    return Promise.resolve(newState)
  },
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)