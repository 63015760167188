import { React, useState } from 'react'
import './MiniTable.css'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

function MiniTable({ data, columnKeyNames, newItemFunctions, deleteRowFn }) {
  const [newItem, setNewItem] = useState({})

  // Handle input change
  const handleInputChange = (columnKeyName, value) => {
    setNewItem((prev) => ({
      ...prev,
      [columnKeyName]: value,
    }))
  }

   // Handle item addition
   const addItem = (columnKeyName) => {
    if (newItem[columnKeyName]) { 
      newItemFunctions[columnKeyName](newItem[columnKeyName])
      
      setNewItem((prev) => ({
        ...prev,
        [columnKeyName]: '',
      }))
    }
  }

  return (
    <div className='mini_table_container'>
      {
        data &&
        data?.map((item, index) => {
          return (
            <div className='mini_table_row items' key={index}>
              {
                columnKeyNames?.map((columnKeyName, index) => {
                  return (
                    <div className='mini_table_row_item' key={index}>{item[columnKeyName]}</div>
                  )
                })
              }
              <div className='delete_icon' onClick={() => deleteRowFn(item)}><DeleteOutlineIcon /></div>
            </div>
          )
        })
      }
      <div className='mini_table_row'>
        {
          columnKeyNames?.map((columnKeyName, index) => {
            return (
              <input
                className='input_field__input_box--text'
                key={index}
                value={newItem[columnKeyName] || ''}
                onChange={(e) => handleInputChange(columnKeyName, e.target.value)}
                onBlur={() => addItem(columnKeyName)}
              />            )
          })
        }
      </div>
    </div>
  )
}

export default MiniTable

MiniTable.defaultProps = {
  cadastralKeyNames: [],
  data: [],
}