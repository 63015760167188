import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux"
import './CompaniesPage.css'
import httpClient from '../components/helpers/httpClient'
import { useNavigate, Link, Navigate } from "react-router-dom"
import MenuBar from "../components/MenuBar"
import Header from "../components/Header"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import { updateFilters, updateCompaniesList, resetStateCompaniesPage, updateCompanyFunctions } from "../store/companiesPage"
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import DropDownFilter from '../components/DropDownFilter'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Dropdown from '../components/Dropdown'
import CheckBox from '../components/CheckBox'
import { DateField } from '@mui/x-date-pickers'
import { toast } from "react-toastify"
import Pagination from '../components/Pagination'
import DropdownMultiselect from '../components/DropdownMultiselect'

function CompanyPage() {
  const [loadingCompanies, setLoadingCompanies] = useState(false)
  const [limit, setLimit] = useState(20)
  const [totalPages, setTotalPages] = useState(1)
  const [tableBig, setTableBig] = useState(true)
  const [companyType, setCompanyType] = useState("")
  const [selectedCompanyFunctions, setSelectedCompanyFunctions] = useState([])

  console.log(selectedCompanyFunctions)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const filters = useSelector(state => state.companiesPage.filtersCompanies)
  const companies = useSelector(state => state.companiesPage.companiesList)
  const companyFunctions = useSelector(state => state.companiesPage.companyFunctions)

  const formSectionAddressInfo = useRef(null)
  const formSectionShippingInfo = useRef(null)

  const arrayOfCities = [...new Set(companies.map(company => {
    if (company.city !== null && company.city !== undefined) {
      return company.city
    }
  }).filter(place => place !== undefined))]

  const fetchCompanies = async () => {
    setLoadingCompanies(true)

    const requestData = {
      filters: {
        city: filters.cityFilter,
        search_word: filters.searchWord,
      },
      sort: filters.sortFilter,
      sortAscending: filters.sortAscending,
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/companies/get?page=" + filters.page + "&limit=" + limit, requestData)
    dispatch(updateCompaniesList(response.data[0]))
    setTotalPages(response.data[1])

    setLoadingCompanies(false)
  }

  useEffect(() => {
    fetchCompanies()
    setLoadingCompanies()
  }, [])

  useEffect(() => {

    fetchCompanies()
      .catch(err => {
        console.log(err)
      })

  }, [filters])

  const handleOnClickRow = (event) => {
    const companyID = event.currentTarget.getAttribute("data-company-id")

    navigate(`/companies/${companyID}`)
  }

  const handleSetSortFilter = (event) => {

    const innerHTML = event.currentTarget.innerHTML
    const updatedFilters = { ...filters }

    switch (innerHTML) {
      case "id":
        if (filters.sortFilter == "id") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "id"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Last Name":
        if (filters.sortFilter == "last_name") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "last_name"
          updatedFilters["sortAscending"] = true
        }
        break
      case "First Name":
        if (filters.sortFilter == "first_name") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "first_name"
          updatedFilters["sortAscending"] = true
        }
        break
      case "City":
        if (filters.sortFilter == "city") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "city"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Email":
        if (filters.sortFilter == "email") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "email"
          updatedFilters["sortAscending"] = true
        }
        break
    }
    updatedFilters["page"] = 1
    dispatch(updateFilters(updatedFilters))
  }


  const handleOnClickDropdownItem = (event) => {

    const filter = event.currentTarget.textContent
    event.target.parentElement.previousSibling.firstChild.textContent = filter
    event.target.parentElement.classList.toggle("active")

    const updatedFilters = { ...filters }

    console.log(filter)

    switch (event.target.parentElement.id) {
      case "companies__dropdownList--city":
        updatedFilters["cityFilter"] = filter
        document.getElementById("companies__dropdownList--city").classList.remove("active")
        break
    }

    updatedFilters["page"] = 1
    dispatch(updateFilters(updatedFilters))
  }

  const handleSearchWord = (event) => {
    const updatedFilters = { ...filters }
    updatedFilters["searchWord"] = event.target.value
    updatedFilters["page"] = 1

    dispatch(updateFilters(updatedFilters))
  }

  const handleClearSearchBar = () => {
    if (filters.searchWord == "") {
      return
    } else {
      const updatedFilters = { ...filters }
      updatedFilters["searchWord"] = ""
      updatedFilters["page"] = 1

      dispatch(updateFilters(updatedFilters))
    }
  }

  const handlePagination = (event) => {
    const element = event.currentTarget

    if (element.classList.contains("grayed-out")) {
      return
    }

    const type = element.dataset.type

    const updatedFilters = { ...filters }

    switch (type) {

      case "previous":
        updatedFilters["page"] = filters.page - 1

        dispatch(updateFilters(updatedFilters))
        break
      case "next":
        updatedFilters["page"] = filters.page + 1

        dispatch(updateFilters(updatedFilters))
        break
    }
  }

  const handleCreateNewCompany = () => {
    const newCompanyForm = document.getElementById("new-company-form")
    const allCompaniesTable = document.getElementById("all-companies-table")
    const saveButton = document.getElementById("companies__btn-save")
    const filters = document.getElementById("company__table-btns")

    newCompanyForm.classList.toggle("hide")
    allCompaniesTable.classList.toggle("big")
    saveButton.classList.toggle("hide")
    filters.classList.toggle("hide")

    setTableBig(!tableBig)
  }

  const handleSaveNewCompany = async () => {
    document.getElementById("companies__btn-save").disabled = true

    let okToSave = true
    const formData = {}

    const emailErrorElement = document.getElementById("input_error_email")
    const email = document.getElementById("company__email")

    const emailValidation = /\S+@\S+\.\S+/

    const inputElements = document.querySelectorAll('.input_field__input_box--text')

    inputElements.forEach(element => {
      const fieldName = element.name
      formData[fieldName] = element.value
    })

    formData["company_type"] = companyType
    formData["company_functions"] = selectedCompanyFunctions

    // let arrayRequiredTextBoxes = []

    // arrayRequiredTextBoxes.forEach((inputElement) => {
    //   if (!inputElement.value) {
    //     inputElement.classList.add("red-border")
    //   }
    // })

    // if (!arrayRequiredTextBoxes.every(item => item.value)) {
    //   okToSave = true
    // }

    if (email.value) {
      if (emailValidation.test(email.value)) {
        email.classList.remove("red-border")
        emailErrorElement.classList.add("hide")
      } else {
        email.classList.add("red-border")
        emailErrorElement.classList.remove("hide")
        okToSave = false
      }
    }

    if (!okToSave) {
      toast.error("Please complete all fields")
      document.getElementById("companies__btn-save").disabled = false
      return
    }

    const response = await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + "/api/company/create", formData),
      {
        pending: "Creating a new company...",
        success: "New company created 👍",
        error: "Something went wrong 😢"
      }
    )
    console.log(response.data)

    // cleaning up form

    inputElements.forEach(element => {
      element.value = ""
    })

    setCompanyType("")
    setSelectedCompanyFunctions([])
    fetchCompanies()

    document.getElementById("companies__btn-save").disabled = false
  }

  const handleSetSelectedCompanyFunctions = (value, action) => {
    if (action == "add") {
      setSelectedCompanyFunctions(prevArray => [...prevArray, value])
    } else {
      setSelectedCompanyFunctions(prevArray => prevArray.filter(item => item.id !== value.id))
    }
  }

  return (
    <div className='template'>

      <Header>
        <div className="header__filters" id="company__table-btns">
          <div className="search-bar" id="companies__search-bar--table" onClick={handleClearSearchBar}>
            <input placeholder="Search company..." value={filters.searchWord} onChange={handleSearchWord} />
            {filters.searchWord == "" ? <SearchIcon /> : <ClearIcon />}
          </div>
          <DropDownFilter
            idList="companies__dropdownList--city"
            placeholderText="Filter on city"
            listArray={arrayOfCities.sort((a, b) => a.localeCompare(b))}
            onClickItem={handleOnClickDropdownItem}
            filters={filters}
            value={filters.cityFilter}
            filter={"cityFilter"}
            setValue={updateFilters}
          />
        </div>

        <button className="primary-button-header--right hide" id="companies__btn-save" onClick={handleSaveNewCompany}>SAVE</button>

      </Header>

      <MenuBar />

      <div className="template__body" id='body-companies'>
        <button className="floating__button--bottom-right" id="add--btn" onClick={handleCreateNewCompany} />

        <div className="body__section hide" id="new-company-form">
          <div className="form__section">

            <div className="input_field">
              <label className="input_field__label" htmlFor="company_name">Name</label>
              <input className="input_field__input_box--text" id="company_name" type="text" name="company_name" />
            </div>

            <div className="input_field--combo-5050--grid">
              <div className="input_field">
                <label className="input_field__label" htmlFor="company_number">Company number</label>
                <input className="input_field__input_box--text" id="company_number" type="text" name="company_number" />
              </div>

              <div className='flex--vert'>
                <label className="input_field__label" htmlFor="company_number">Company type</label>
                <Dropdown
                  index={"company-type"}
                  listArray={["NV", "BV", "VOF", "CV", "MS", "CommV"]}
                  setState={setCompanyType}
                  placeholder={""}
                  width={"100%"}
                  searchBar={true}
                  margin={"0"}
                />
              </div>
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="company_functions">Company functions</label>
              <DropdownMultiselect
                index={"company-functions"}
                listArray={companyFunctions}
                state={selectedCompanyFunctions}
                setState={handleSetSelectedCompanyFunctions}
                placeholder={""}
                width={"100%"}
                displayValueKey={"function"}
              />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="email">Email</label>
              <input className="input_field__input_box--text" id="company__email" type="text" name="email" />
            </div>
            <div className="input_error hide" id="input_error_email">I suspect this is not a valid email address 🤖</div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="email">Website</label>
              <input className="input_field__input_box--text" id="company__website" type="text" name="website" />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="phone">Business phone</label>
              <input className="input_field__input_box--text" id="company_phone" type="text" name="phone" />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="total_amount_of_shares">company shares amount</label>
              <input className="input_field__input_box--text half" id="company_total_shares" type="text" name="total_amount_of_shares" defaultValue={0} />
            </div>
          </div>

          <div className="form__section" id="address_info" ref={formSectionAddressInfo}>
            <div className="input_field">
              <label className="input_field__label" htmlFor="address_street">Address</label>
              <input className="input_field__input_box--text" id="company__address" type="text" name="street" />
            </div>
            <div className="input_field--combo-3070">
              <div className="input_field">
                <label className="input_field__label" htmlFor="address_pc">Postal code</label>
                <input className="input_field__input_box--text" id="company__address_pc" type="text" name="postal_code" />
              </div>
              <div className="input_field">
                <label className="input_field__label" htmlFor="address_place">City</label>
                <input className="input_field__input_box--text" id="company__address_place" type="text" name="city" />
              </div>
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="address_province">Province</label>
              <input className="input_field__input_box--text" id="companies__address_place" type="text" name="province" />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="address_nr">Country</label>
              <input className="input_field__input_box--text" id="company__address_country" type="text" name="country" defaultValue={"Belgium"} />
            </div>
          </div>


          <div className="form__section" id="other_info" ref={formSectionShippingInfo}>
            <div className="input_field">
              <label className="input_field__label" htmlFor="address_street">Shipping address</label>
              <input className="input_field__input_box--text" id="company_shipping__address" type="text" name="shipping_street" />
            </div>
            <div className="input_field--combo-3070">
              <div className="input_field">
                <label className="input_field__label" htmlFor="address_pc">Shipping postal code</label>
                <input className="input_field__input_box--text" id="company_shipping__address_pc" type="text" name="shipping_postal" />
              </div>
              <div className="input_field">
                <label className="input_field__label" htmlFor="address_place">city</label>
                <input className="input_field__input_box--text" id="company_shipping__address_place" type="text" name="shipping_city" />
              </div>
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="address_province">Shipping province</label>
              <input className="input_field__input_box--text" id="company_shipping__address_place" type="text" name="shipping_province" />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="address_nr">Country</label>
              <input className="input_field__input_box--text" id="company_shipping__address_country" type="text" name="shipping_country" defaultValue={"Belgium"} />
            </div>
          </div>
        </div>

        <div className='body__section big' id='all-companies-table'>
          <div className='table'>
            <div className='table__header-5'>
              <div className='table__header--field' onClick={handleSetSortFilter}>id</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Company Number</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Name</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>City</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Email</div>
            </div>
            <div className='table__rows_container'>

              {
                loadingCompanies ?
                  <LoadingSpinner /> :

                  companies.map((company, index) => {
                    return (
                      <div className='table__row-5' key={index} onClick={handleOnClickRow} data-company-id={company.id}>
                        <div className='table__row--field'>{company.id}</div>
                        <div className='table__row--field'>{company.company_number}</div>
                        <div className='table__row--field'>{company.company_name}</div>
                        <div className='table__row--field'>{company.city}</div>
                        <div className='table__row--field'>{company.email}</div>
                      </div>
                    )
                  })
              }
            </div>
            <div className="pagination">
              <ArrowBackIosIcon className={`pagination__btn ${filters.page == 1 ? "grayed-out" : ""}`} onClick={handlePagination} data-type="previous" id="prevPage" />
              <ArrowForwardIosIcon className={`pagination__btn ${filters.page == totalPages ? "grayed-out" : ""}`} onClick={handlePagination} data-type="next" id="nextPage" />
            </div>
          </div>
        </div>
      </div >

    </div >
  )
}

export default CompanyPage